import axios from 'axios';
import core from './core';

const API: string = process.env.REACT_APP_VOICES_API || 'http://localhost:3001'; // eslint-disable-line no-undef

export const getFilters = (): Promise<any> => {
  return axios.get(`${API}/api/filters`).then(({data: {data}}) => data);
};

export const getTalents = (params: string): Promise<any> => {
  return axios.get(`${API}/api/talents${params}`).then(({data}) => data);
};

export const postLeadGeneratorForm = (hubspotLink: string, fields: any): Promise<any> => {
  const cookieParts = ('; ' + document.cookie).split('; hubspotutk=');
  const payload = {
    submittedAt: Date.now(),
    fields,
    context: {
      hutk: cookieParts.length === 2 ? cookieParts.pop()?.split(';').shift() : '',
      pageUri: document.location.href,
      pageName: document.title,
    },
  };
  return core.post(hubspotLink, payload).then(({data}) => data);
};
